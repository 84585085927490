/* eslint-disable */
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

// import reportWebVitals from './reportWebVitals'

import { ConfigProvider, message, Spin } from 'antd'
import GlobalDataProvider from '@/global'

import App from './App'

import './index.css'

import zhCN from 'antd/locale/zh_CN'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

{/* <React.StrictMode> */}
 
message.config({
  maxCount: 1,
})


root.render(
  <ConfigProvider  
    locale={zhCN}
    theme={{
      token: {
        colorPrimary: '#75B647',
      }
    }}
  >
    <Suspense fallback={<Spin spinning={true}><div style={{width: '100vw', height: '100vh'}}></div></Spin>}>
      <GlobalDataProvider>
        <App />
      </GlobalDataProvider>
    </Suspense>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
