import axios from 'axios'
import { getCookie } from '@/common/common'
import { message } from 'antd'

// 创建axios实例
const service = axios.create({
  baseURL: '/', // api的base_url
  timeout: 5000, // 请求超时时间
})
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    // 例如：
    config.headers['Authorization'] = getCookie('Authorization')

    return config
  },
  error => {
    // 请求错误处理  
    return Promise.reject(error)
  } 
)
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理，例如只返回data部分
    const {status, data} = response
    // 如果返回的状态码为200，说明成功，可以直接返回数据
    if (status === 200) {
      if (data.code === '00001') {
        return data.data
      }
      
      message.error(data.msg)
      if (data.code === '00000') {

        message.error(data.msg)
        localStorage.clear()
        // return Promise.reject(data.msg || 'error')
      }
    } else {
      // console.log(data)
      // 返回的状态码非200，说明有错误，此处可以根据后端返回的错误码进行进一步处理
      return Promise.reject(data || 'error')
    }
  },
  error => {
    // message.error(response.data?.msg || error)

    // console.log('相应 error')
    // 服务器返回的错误处理
    return Promise.reject(error)
  }
)


const request = (
  urlProps: {
    method: 'get' | 'post'
    url: string
  }, 
  params?: {
    [key: string]: any
  }) => {
    if (!urlProps) return

    try {
      const {url, method, ...rest} = urlProps || {}
      let requestParams: any = {}
      if (method === 'get') {
        requestParams = {params: params}
      } else {
        requestParams = params
      }
      const context = service[method](url, {...requestParams}, {
        ...rest,
      })

      return context

    } catch (e: any) {
      console.log(e, '123')
      console.log(e, '3333333')
      // message.error(e.message)
    } finally {
    }

}



export default request
