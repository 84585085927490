const MENU = [{
  name: '首页',
  href: '/'
}, {
  name: '品牌中心',
  href: '/brand'
// }, {
//   name: '促销活动',
//   href: '/promo'
// }, {
//   name: '批量订阅',
//   href: '/subscribe'
}, {
  name: '关于我们',
  href: '/about'
}, {
  name: '联系我们',
  href: '/contact'
}]

const showNumber = (number: number | null | undefined, showUnit: boolean = true) => {
  if (number === undefined || number === null) {
    return '询价'
  }
  
  if (showUnit) {
    return '¥'+ number.toFixed(2) +'元'
  } else {
    return number.toFixed(2)
  }
}

const sum = (arr: number[]) => {
  if (arr.length === 0) return undefined

  return arr.reduce((prev, curr) => prev + curr)
}

const getCookie = (name: string) => {
  // 将document.cookie字符串按照; 分割成数组
  const cookies = document.cookie.split('; ')
  // 遍历cookie数组，寻找指定的cookie
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    // 分割cookie字符串，得到cookie的名字和值
    const [cookieName, cookieValue] = cookie.split('=')
    // 如果找到了指定的cookie名，返回它的值
    if (cookieName === name) {
      return cookieValue
    }
  }
  // 如果没有找到，返回null
  return null
}

const listToTree = (list: any[]) => {
  let map: any = {}, node, tree = [], i
  for (i = 0; i < list.length; i++) {
    map[list[i].id] = i // 初始化映射
    list[i].children = [] // 初始化children数组
  }
  for (i = 0; i < list.length; i++) {
    node = list[i]
    if (node.parentId !== null) {
      // 如果当前节点有父ID，则找到父节点并将当前节点添加到父节点的children数组中
      list[map[node.parentId]].children.push(node)
    } else {
      // 如果没有父ID，则为树的根节点
      tree.push(node)
    }
  }
  return tree
}


// 定义一个函数，用于将两个数字相乘并保留两位小数
function multiplyWithPrecision(num1: number, num2: number) {
  if (num1 === undefined || num2 === undefined) return
  // 将数字转换为字符串，以便于处理小数点
  const num1String = num1.toString()
  const num2String = num2.toString()
 
  // 提取num1和num2的小数点后的位数
  const num1DecimalPlaces = num1String.split('.')[1]?.length || 0
  const num2DecimalPlaces = num2String.split('.')[1]?.length || 0
 
  // 计算乘法后结果的预期小数位数
  const totalDecimalPlaces = num1DecimalPlaces + num2DecimalPlaces
 
  // 进行乘法运算，并四舍五入保留两位小数
  const result = parseFloat((num1 * num2).toFixed(totalDecimalPlaces))
 
  // 返回结果
  return result
}


// 展示地址
const formatAddress = (address: string) => {
  if (!address) return 

  const addressArr = JSON.parse(address) || []

  const strArr = addressArr.map((item: any) => item.label)

  return strArr.join(' ')
}

export {
  MENU,

  showNumber,
  sum,
  getCookie,
  listToTree,
  multiplyWithPrecision,
  formatAddress
}