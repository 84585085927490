// GlobalDataProvider.js
import { useFetch } from '@/hooks/usefetch' 
import { listToTree } from '@/common/common'
import { GlobalDataContext } from './context'
import io from './io'
 
interface TProps {
  children: any
}


const GlobalDataProvider = ({ children }: TProps) => {
  const username = localStorage.getItem('username')

  const sidebar = useFetch(io.getProductCategories) || {}

  /**
    * @Author 不悔
    * @Date 2024-08-15
    * @desrc 获取购物车商品数量
    * @export 
    * @param {String} 
    * 
  */
  const carList = useFetch(io.getCartItemList, {fetch: username ? true : false}) || {}
  
  return (
    <GlobalDataContext.Provider 
      value={{
        sidebar: {
          ...sidebar,
          result: listToTree(sidebar.result || [])
        },
        carList,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  )
}

export default GlobalDataProvider