import {useEffect, useState} from 'react'
import request from '@/common/request'
import {message} from 'antd'

interface TFetcher {
  method: 'get' | 'post'
  url: string
}

/**
 *
 * @param {*} fetcher
 * @param {*} initData
 *
 */
export function useFetch (fetcher: TFetcher, initData: any | undefined = {}, cb: any | undefined = undefined) {

  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<any>(undefined)
  const [params, setParams] = useState<any | undefined>(initData)


  const fetchData = async () => {
    try {
      setLoading(true) 
      const {fetch, refresh, ...rest} = params

      // 请求数据
      const res: any = await request(fetcher, {...rest})
      setResult(res)

      cb && cb(res)
      
    } catch (e: any) {
      message.error(e.msg || e.message)
      console.log(e, 'e')
    } finally {
      setLoading(false)
    }
  }

  // 修改参数重新调用
  const handleChange = (data: any = {}) => {
    const {fetch, ...rest} = data

    setParams({
      ...rest,
      refresh: Math.random()
    })
  }



  useEffect(() => {
    const {fetch = true} = params

    if (fetch) {
      fetchData()
    }
}, [JSON.stringify(params)])// eslint-disable-line react-hooks/exhaustive-deps



  
  return {
    loading,
    result,
    handleChange
  }
}