
interface TFetcher {
  method: 'get' | 'post'
  url: string
}

const io: {
  [key: string]: TFetcher
} = {
  getProductCategories: {
    url: '/mall-portal/home/productCategories',
    method: 'get'
  },
  getCartItemList: {
    url: '/mall-portal/cartItem/cartItemList',
    method: 'get'
  },
}


export default io