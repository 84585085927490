import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from '@/pages/home'
import Login from '@/pages/login' 
import Search from '@/pages/search'
import Brand from '@/pages/brand'
import BrandDetail from '@/pages/brand-detail'
import Promo from '@/pages/promo'
import Subscribe from '@/pages/subscribe'
import Class from '@/pages/class'
import Product from '@/pages/product'
import Contact from '@/pages/contact'
import About from '@/pages/about'
import Buycar from '@/pages/buycar'
import Success from '@/pages/success'
import Settle from '@/pages/settle'
import Confirm from '@/pages/confirm'
import MyOrder from '@/pages/my-order'
import MyOrderDetail from '@/pages/my-order/my-order-detail'
import UserInfo from '@/pages/user-info'
import OrderInfo from '@/pages/order-info'
import ReceiveInfo from '@/pages/receive-info'
import InvoiceInfo from '@/pages/invoice-info'
import Message from '@/pages/message'
import ForgetPassword from '@/pages/forget-password'
import ResetPassword from '@/pages/reset-password'
import UserAgreement from '@/pages/user-agreement'
import ImmersiveAgreement from '@/pages/immersive-agreement'

import '@/common/flex.styl'
import '@/common/common.styl'


function App() {
  return ( 
    <Router>
      <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/search" element={<Search />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/brand/detail/:id" element={<BrandDetail />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/class" element={<Class />} />
        <Route path="/product" element={<Product />} />
        <Route path="/buycar" element={<Buycar />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/message" element={<Message />} />
        <Route path="/success" element={<Success />} />
        <Route path="/settle" element={<Settle />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/my-order" element={<MyOrder />} />
        <Route path="/my-order-detail" element={<MyOrderDetail />} />
        <Route path="/user-info" element={<UserInfo />} />
        <Route path="/order-info" element={<OrderInfo />} />
        <Route path="/receive-info" element={<ReceiveInfo />} />
        <Route path="/invoice-info" element={<InvoiceInfo />} />
        <Route path="/user-agreement" element={<UserAgreement />} />        
        <Route path="/immersive-agreement" element={<ImmersiveAgreement />} />        
      </Routes>
    </Router>
  )
}

export default App;
